/** @format */

import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  Suspense,
} from "react";
// import AWS, { CognitoIdentityCredentials } from "aws-sdk";
import Pool from "../../UserPool";
import "cross-fetch/polyfill";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import Layout from "../../pages/Layout";
import Env from "../../env.js";
import { AttributeContext } from "./Attributes";

const AccountContext = createContext();

const Account = (props) => {
  const [userSaved, setUserSaved] = useState(null);
  const [isMfaRequired, setIsMfaRequired] = useState(false);
  // const [cachedSession, setCachedSession] = useState(null);

  //attribute context
  const Attributes = useContext(AttributeContext);
  const {
    setCogid,
    cogid,
    setStagingFlag,
    setEmail,
    setMfa,
    loggedIn,
    setLoggedIn,
  } = Attributes;

  const navigate = useNavigate();
  // const dashboard = Env.SLUG + "dashboard";
  const apppage = Env.SLUG + "apppage";

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            setLoggedIn(false);
            reject();
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results = {};

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                    if (Name === "custom:prefferedcogid") {
                      //console.log("prefferedcogid: ", Value);
                      setCogid(Value);
                    }
                    if (Name === "custom:staging") {
                      //console.log("prefferedcogid: ", Value);
                      setStagingFlag(Value);
                    }
                    if (Name === "email") {
                      //console.log("prefferedcogid: ", Value);
                      setEmail(Value);
                    }
                  }

                  user.getUserData((err, userData) => {
                    if (err) {
                      reject(err);
                    } else {
                      const mfaType = userData.PreferredMfaSetting;
                      setMfa(mfaType);
                      resolve(results);
                    }
                  });

                  resolve(results);
                }
              });
            });

            loadCOGintoHash();

            console.log("running pullTokens from getSession");
            pullTokens(session, user).then((session) => {
              console.log(
                "From Account - pullTokens - getSession: ran pullTokens"
              );
              console.log("From Account - pullTokens- getSession: ", session);
            });

            resolve({ user, ...session, ...attributes });
          }
        });
      } else {
        // console.log("No user is currently logged in.");
        setLoggedIn(false);
        //When I was troubleshooting the Userdashboard not refreshing the account email when I would change it, this was working. After adding the navigate back into this function it stropped working again.
        //Bug below was resolved when I came back for testing.
        //This line breaks the login page. Need to troubleshoot.
        //reject();
      }
    });
  };

  const pullTokens = async (data, user) => {
    return await new Promise((resolve, reject) => {
      const accessToken = new CognitoAccessToken({
        AccessToken: data.accessToken.jwtToken,
      });
      const idToken = new CognitoIdToken({ IdToken: data.idToken.jwtToken });
      const refreshToken = new CognitoRefreshToken({
        RefreshToken: data.refreshToken.token,
      });

      const sessionData = {
        IdToken: idToken,
        AccessToken: accessToken,
        RefreshToken: refreshToken,
      };
      const cachedSessionPull = new CognitoUserSession(sessionData);
      // const cachedSessionPull = false;
      // setCachedSession(cachedSessionPull);

      const validSession = async () => {
        if (cachedSessionPull === null) {
          logout();
          return false;
        } else {
          try {
            if (await cachedSessionPull.isValid()) {
              return true;
            } else {
              console.log(
                "From Account - pullTokens: cachedSession is not valid"
              );
              refreshExpiredSession(user, refreshToken);
              return false;
            }
          } catch (error) {
            console.error("Error validating cached session: ", error);
            refreshExpiredSession(user, refreshToken, cachedSessionPull);
            return false;
          }
        }
      };

      validSession();

      resolve(cachedSessionPull);
    });
  };

  const refreshExpiredSession = async (user, refreshToken, sessionData) => {
    user.refreshSession(refreshToken, (err, session) => {
      if (err) {
        console.error(
          "From Account - refreshExpiredSession - refreshSession: ",
          err
        );
        logout();
      } else {
        console.log(
          "From Account - refreshExpiredSession - refreshSession: ",
          session
        );
      }
    });
  };

  //const authenticate function
  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("From Account - onSuccess: ", data);
          resolve(data);
          sessionStorage.setItem("username", Username);

          // Retrieve the access token
          console.log("running pullTokens from authenticate");
          pullTokens(data, user).then((session) => {
            console.log(
              "From Account - pullTokens - authenticate: ran pullTokens"
            );
            // console.log("From Account - pullTokens- authenticate: ", session);
            // console.log("From Account - pullTokens- authenticate - data: ", data);
            // setAccessToken(session.getAccessToken().getJwtToken());
            // console.log("From Account - pullTokens- authenticate - accessToken: ", accessToken);
            // setRefreshToken(session.getRefreshToken().getToken());
            // console.log("From Account - pullTokens- authenticate - RefreshToken: ", refreshToken);
          });

          navigate(apppage);
          setUserSaved(user);
        },

        onFailure: (err) => {
          // console.error("from Account - onFailure :", err);
          reject(err);
        },

        newPasswordRequired: (data) => {
          // console.log("newPasswordRequired: ", data);
          //new error(data)
          resolve(data);
        },

        mfaSetup: function (challengeName, challengeParameters) {
          user.associateSoftwareToken(this);
          setUserSaved(user);
        },

        associateSecretCode: function (secretCode) {
          setUserSaved(user);
          var challengeAnswer = prompt("Please input the TOTP code.", "");
          user.verifySoftwareToken(challengeAnswer, "My TOTP device", this);
        },

        selectMFAType: function (challengeName, challengeParameters) {
          setUserSaved(user);
          var mfaType = prompt("Please select the MFA method.", ""); // valid values for mfaType is "SMS_MFA", "SOFTWARE_TOKEN_MFA"
          user.sendMFASelectionAnswer(mfaType, this);
        },

        totpRequired: function (secretCode) {
          setIsMfaRequired(true);
          setUserSaved(user);
          // console.log("TOTP Required: ", secretCode);
          // user.sendMFACode(challengeAnswer, this, 'SOFTWARE_TOKEN_MFA');
        },

        mfaRequired: function (codeDeliveryDetails) {
          var verificationCode = prompt("Please input verification code", "");
          user.sendMFACode(verificationCode, this);
        },
      });
    });
  };

  const sendMfaCode = (mfaCode) => {
    return new Promise((resolve, reject) => {
      if (userSaved) {
        userSaved.sendMFACode(
          mfaCode,
          {
            onSuccess: function (result) {
              // console.log(
              //   "access token + " + result.getAccessToken().getJwtToken()
              // );

              pullTokens(result).then((session) => {
                console.log(
                  "From Account - pullTokens- sendMfaCode: ",
                  session
                );
                console.log(
                  "From Account - pullTokens- sendMfaCode - result: ",
                  result
                );
              });

              navigate(apppage);
              resolve(result);
            },
            onFailure: function (err) {
              console.error(err);
              reject(err);
            },
          },
          "SOFTWARE_TOKEN_MFA"
        );
      } else {
        reject("No user is currently logged in.");
      }
    });
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  };

  const resetPassword = async (Username) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });

      // call forgotPassword on cognitoUser
      user.forgotPassword({
        onSuccess: function (result) {
          // console.log("user: ", user);
          // console.log("call result: " + result);
          resolve(result);
        },
        onFailure: function (err) {
          alert(err);
          reject(err);
        },
        inputVerificationCode() {
          // this is optional, and likely won't be implemented as in AWS's example (i.e, prompt to get info)
          var verificationCode = prompt(
            "Please input verification code that was sent to your email address. Please allow a few minutes for the code to be delivered.",
            ""
          );
          var newPassword = prompt("Enter your new replacement password", "");
          user.confirmPassword(verificationCode, newPassword, this);
        },
      });
    });
  };

  const loadCOGintoHash = () => {
    if (cogid === "" || cogid === null || cogid === undefined) {
      return;
    } else {
      window.location.hash = "cogid=" + cogid;
      //console.log("COG ID: ", cogid)
    }
  };

  useEffect(() => {
    getSession()
      .then((session) => {
        // console.log("From Account - getSession- useEffect: ", session);
        setLoggedIn(true);
      })
      .catch((err) => {
        setLoggedIn(false);
        // console.log("From Account - getSession- useEffect: ", err);
      });
  });

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logout,
        resetPassword,
        sendMfaCode,
        isMfaRequired,
      }}
    >
      {props.children}
      <Suspense fallback={<div>Loading...</div>}>
        <Layout loggedIn={loggedIn} />
      </Suspense>
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
